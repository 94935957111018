import './globals.css'
import Navbar from './components/Navbar'
import Footer from './components/Footer';
import { Providers } from "./providers";
import CookieConsentBanner from "@/app/components/CookieConsentBanner";
import CTAButton from '@/app/components/CTAButton';

export default function RootLayout({ children }) {
  return (
    <Providers>
      <Navbar />
      <main className="flex min-h-screen flex-col items-center justify-between text-neutral-dark bg-background">
        {children}
      </main>
      <CookieConsentBanner />
      <CTAButton />
      <Footer />
    </Providers>
  )
}
