'use client'

import React, { useState, useEffect } from "react";
import Cookie from "js-cookie";
import { Snackbar, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function CookieConsentBanner() {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const consentCookie = Cookie.get("cookieConsent");
        if (!consentCookie) {
            setOpen(true);
        }
    }, []);

    const handleAccept = () => {
        setOpen(false);
        Cookie.set("cookieConsent", "accepted", {
            expires: 365, // Cookie expiration time in days
            path: '/', // Specifies the path in which the cookie is valid
            secure: true, // Ensures the cookie is sent over HTTPS
            SameSite: 'Strict' // Can be 'Strict', 'Lax', or 'None'. 'Strict' is recommended for most cases
        });    };

    const handleReject = () => {
        setOpen(false);
        Cookie.set("cookieConsent", "rejected", {
            expires: 365, // Cookie expiration time in days
            path: '/', // Specifies the path in which the cookie is valid
            secure: true, // Ensures the cookie is sent over HTTPS
            SameSite: 'Strict' // Can be 'Strict', 'Lax', or 'None'. 'Strict' is recommended for most cases
        });    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" aria-label="Cookies zustimmen" size="small" onClick={handleAccept}>
                Zustimmen
            </Button>
            <Button color="error" aria-label="Cookies ablehnen" size="small" onClick={handleReject}>
                Ablehnen
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            message="Um Ihnen ein optimiertes Erlebnis auf unserer Webseite zu bieten, verwenden wir Cookies. Einige dieser Cookies sind für das einwandfreie Funktionieren der Webseite unerlässlich und können nicht abgelehnt werden. Durch Zustimmen akzeptieren Sie die Nutzung aller Cookies. Details zu den verwendeten Cookies und Ihren Wahlmöglichkeiten finden Sie in unserer Datenschutzerklärung."
            action={action}
            sx={{
                '& .MuiSnackbarContent-root': {
                    backgroundColor: '#333',
                    color: '#fff',
                    flexWrap: 'wrap', // Allow items to wrap in smaller screens
                    maxWidth: 'calc(100vw - 40px)', // Ensure it doesn't exceed viewport width
                    '& .MuiButton-root': {
                        color: '#fff',
                        margin: '4px', // Add spacing around buttons for smaller screens
                    },
                    '& .MuiIconButton-root': {
                        padding: '4px', // Reduce padding for the close icon button
                        margin: '0 4px', // Consistent margin for close icon
                    }
                },
            }}
        />
    );
}
