'use client'

import React from "react";
import { Link, Card, CardBody } from "@nextui-org/react";

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className="footer" id="footer">
            <Card className="footer-card bg-primary-light-secondary" fullWidth radius="none">
                <CardBody className="items-center items-centered">
                    <div className="flex gap-4 footer-texts">
                    <p className="text-neutral-nav">&copy; Copyright {year}</p>
                        <Link href="/datenschutz" className="text-neutral-nav">Datenschutz</Link>
                        <Link href="/impressum" className="text-neutral-nav">Impressum</Link>
                        <Link href="/agb" className="text-neutral-nav">AGB</Link>
                    </div>
                </CardBody>
            </Card>
        </footer>
    );
}