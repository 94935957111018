'use client'

import { NextUIProvider } from '@nextui-org/react'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme'


export function Providers({ children }) {
  return (
    <NextUIProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextUIProvider>
  )
}