'use client'

import React, { useState } from "react";
import { useRouter } from 'next/router';
import { Typography } from "@mui/material";
import { Navbar, NavbarBrand, NavbarMenuToggle, NavbarMenuItem, NavbarMenu, NavbarContent, NavbarItem, Link, Button, DropdownMenu, DropdownItem, Dropdown, DropdownTrigger } from "@nextui-org/react";
import Image from "next/image";

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const openDropdown = () => {
    console.log("open")
    setDropdownOpen(true);
  }
  const closeDropdown = () => {
    console.log("close")
    setDropdownOpen(false);
  }

  const handleNavigation = async (sectionId) => {
    if (router.pathname === '/') {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      await router.push('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Added delay to ensure the element is available after navigation
    }
  };

  return (
    <>
      <Navbar className="bg-primary-light-secondary text-neutral-nav navbar" isBlurred isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} maxWidth="2xl" style={{ zIndex: '1001' }}>
        <NavbarBrand>
          <Link href="/" className="font-bold text-inherit" size="lg">
            <Image src="/images/logo.png" height={75} width={75} alt="Logo" style={{ paddingRight: '0.5rem' }} />
          </Link>
        </NavbarBrand>
        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          <NavbarItem>
            <Link href="#angebot" onClick={() => handleNavigation('angebot-section')} className="font-bold block w-full text-neutral-nav" size="lg">
              Angebot
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link href="#uebermich" onClick={() => handleNavigation('uebermich-section')} className="font-bold block w-full text-neutral-nav" size="lg">
              Über mich
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link href="#praxis" onClick={() => handleNavigation('portfolio-section')} className="font-bold block w-full text-neutral-nav" size="lg">
              Praxis
            </Link>
          </NavbarItem>
          <NavbarItem onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
          <Dropdown isOpen={isDropdownOpen}>
              <DropdownTrigger>
                <Button style={{ padding: '0' }}
                  className="font-bold">
                  Therapiemethoden
                </Button>
              </DropdownTrigger>
              <DropdownMenu className="bg-primary-light-secondary text-neutral-dark dropdown-navigation custom-dropdown-menu" aria-label="Therapiemethoden">
                <DropdownItem style={{ padding: '0px' }}>
                  <Typography>
                    <Link href="/dorntherapie" className="font-bold block w-full text-neutral-nav" size="lg" style={{ padding: '1rem' }}>
                      Dorntherapie
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem style={{ padding: '0px' }}>
                  <Typography>
                    <Link href="/ernaehrung" className="font-bold block w-full text-neutral-nav" size="lg" style={{ padding: '1rem' }}>
                      Ernährung
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem style={{ padding: '0px' }}>
                  <Typography>
                    <Link href="/iridologie" className="font-bold block w-full text-neutral-nav" size="lg" style={{ padding: '1rem' }}>
                      Iridologie
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem style={{ padding: '0px' }}>
                  <Typography>
                    <Link href="/massage" className="font-bold block w-full text-neutral-nav" size="lg" style={{ padding: '1rem' }}>
                      Massage
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem style={{ padding: '0px' }}>
                  <Typography>
                    <Link href="/triggerpunkt" className="font-bold block w-full text-neutral-nav" size="lg" style={{ padding: '1rem' }}>
                      Triggerpunkt
                    </Link>
                  </Typography>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem>
            <Link href="/qualifikationen" size="lg" className="font-bold text-neutral-nav">
              Qualifikationen
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link href="/kontakt" size="lg" className="font-bold text-neutral-nav">
              Kontakt
            </Link>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4" justify="end">
        </NavbarContent>
        <NavbarContent className="sm:hidden" justify="end">
          <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
        </NavbarContent>

        <NavbarMenu className="bg-primary-light-secondary" style={{ paddingTop: '2.5rem', zIndex: '1001' }}>

          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="#angebot" onClick={() => handleNavigation('angebot-section')} className="font-bold block w-full text-neutral-nav nav-accordion-link" size="lg">
                Angebot
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="#uebermich" onClick={() => handleNavigation('uebermich-section')} className="font-bold block w-full text-neutral-nav nav-accordion-link" size="lg">
                Über mich
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="#praxis" onClick={() => handleNavigation('portfolio-section')} className="font-bold block w-full text-neutral-nav nav-accordion-link" size="lg">
                Praxis
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/dorntherapie" className="font-bold block w-full text-neutral-nav" size="lg">
                Dorntherapie
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/ernaehrung" className="font-bold block w-full text-neutral-nav nav-accordion-link" size="lg">
                Ernährung
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/iridologie" className="font-bold block w-full text-neutral-nav nav-accordion-link" size="lg">
                Iridologie
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/massage" className="font-bold block w-full text-neutral-nav nav-accordion-link" size="lg">
                Massage
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/triggerpunkt" className="font-bold block w-full text-neutral-nav nav-accordion-link" size="lg">
                Triggerpunkt
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/qualifikationen" className="font-bold block w-full text-neutral-nav" size="lg">
                Qualifikationen
              </Link>
            </Typography>
          </NavbarMenuItem>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/kontakt" className="font-bold block w-full text-neutral-nav" size="lg">
                Kontakt
              </Link>
            </Typography>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
    </>
  );
}
